<template>
  <b-modal id="article-filters-modal" size="lg" title="Filter Articles" v-model="showArticlesModal">
    <b-overlay :show="loading" rounded="sm">
      <div class="formRow">
        <div class="formElement single">
          <div class="formElement__label">
            <label for="supplier-select">Select Supplier <span class="text-danger">*</span></label>
          </div>
          <div class="formElement__input">
            <v-select name="supplier-select" id="supplier-select" taggable placeholder="Please select" class="wide"
                      :create-option="supplierName => ({ name: supplierName, id: 0})"
                      v-model="supplier" label="name"
                      :options="suppliers"></v-select>
          </div>
        </div>
      </div>
      <div class="formRow">
        <div class="formElement single">
          <div class="formElement__label">
            <label for="supplier-select">Select Max Price position</label>
          </div>
          <div class="formElement__input">
            <v-select name="supplier-select" id="supplier-select" taggable :placeholder="`Please select`" class="wide"
                      v-model="maxPricePosition" label="name"
                      :options="pricePositions"></v-select>
          </div>
        </div>
      </div>
    </b-overlay>
    <template #modal-footer>
      <b-button variant="success" @click="applyFilters">
        Apply
      </b-button>
      <b-button variant="light" @click="reset">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ArticlesFiltersModal",
  data() {
    return {
      supplier: null,
      maxPricePosition: null,
      pricePositions: [
        'Price # 1',
        'Price # 2',
        'Price # 3',
        'Price # 4',
        'Price # 5',
      ]
    }
  },
  components: {},
  computed: {
    showArticlesModal: {
      get() {
        return this.$store.state.articles.modals.isFiltersModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('articles/openFiltersModal');
        } else {
          this.$store.dispatch('articles/closeFiltersModal');
        }
      }
    },
    ...mapState({
      loading: state => state.articles.isLoading,
      suppliers: state => state.ancillaries.suppliers,
    })
  },

  methods: {
    applyFilters() {
      this.$store.dispatch('articles/applySupplierFilter', {
        supplier: this.supplier,
        maxPricePosition: this.maxPricePosition
      });
      this.$store.dispatch('articles/closeFiltersModal');
    },
    reset() {
      this.$store.dispatch('articles/closeFiltersModal');
    }
  }

}
</script>

