<template>
  <b-modal id="upload-articles-modal" size="lg" title="Upload Articles" v-model="showArticlesModal">
    <b-overlay :show="loading" rounded="sm">
      <form>
        <p>This tool allows you to upload article exports from other systems to sync the article database within Spirit 2.0.</p>

        <p>Please select the raw Article Export file to continue. The file should match <a href="assets/ArticleUploadExample.xlsx" target="_blank">This format</a>.</p>
        <b-alert variant="danger" :show="this.errors.length > 0">
          <ul>
            <li v-for="error in this.errors" :key="error">{{ error }}</li>
          </ul>
        </b-alert>

        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label for="file_upload">Upload file</label>
            </div>
            <div class="formElement__input">
              <b-form-file
                  :plain="true"
                  v-model="file"
                  :state="Boolean(file)"
                  id="file_upload"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
              ></b-form-file>
            </div>
          </div>
        </div>
      </form>
    </b-overlay>
    <template #modal-footer>
      <b-button variant="success" @click="tryUpload">
        Upload
      </b-button>
      <b-button variant="light" @click="reset">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>
export default {
  name: "UploadArticlesModal",
  data() {
    return {
      file: null,
      errors: [],
      loading: false,
    }
  },
  components: {},
  computed: {
    showArticlesModal: {
      get() {
        return this.$store.state.articles.modals.isAddModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('articles/openAddModal');
        } else {
          this.$store.dispatch('articles/closeAddModal');
        }
      }
    }
  },

  methods: {

    tryUpload() {

      this.loading = true;
      this.$store.dispatch('articles/uploadArticles', {
        file: this.file,
      }).then(() => {
        this.$toast( 'File uploaded successfully. Please wait a few minutes for processing.');
        this.reset();
      });
    },

    reset() {
      this.loading = false;
      this.$store.dispatch('articles/closeAddModal');
    },
  }

}
</script>

