<template>
  <b-modal id="upload-purchase-statistics-modal" size="lg" title="Upload Purchase Statistics" v-model="showModal">
    <b-overlay :show="loading" rounded="sm">
      <form>
        <p>This tool allows you to upload Purchase Statistics from Spirit. Please select the file to continue.</p> <p>The file should match <a href="assets/PurchaseStatisticsUpload.xlsx" target="_blank">This format</a>.</p>
        <b-alert variant="danger" :show="this.errors.length > 0">
          <ul>
            <li v-for="error in this.errors" :key="error">{{ error }}</li>
          </ul>
        </b-alert>

        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label>Upload file</label>
            </div>
            <div class="formElement__input">
              <b-form-file
                  :plain="true"
                  v-model="file"
                  :state="Boolean(file)"
                  id="file_upload"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
              ></b-form-file>
            </div>
          </div>
        </div>
      </form>
    </b-overlay>
    <template #modal-footer>
      <b-button variant="success" @click="tryUpload">
        Upload
      </b-button>
      <b-button variant="light" @click="reset">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>
export default {
  name: "UploadPurchaseStatisticsModal",
  data() {
    return {
      file: null,
      errors: [],
      loading: false,
    }
  },
  components: {},
  computed: {
    showModal: {
      get() {
        return this.$store.state.articles.modals.isStatisticsModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('articles/openStatisticsUploadModal');
        } else {
          this.$store.dispatch('articles/closeStatisticsUploadModal');
        }
      }
    }
  },

  methods: {

    tryUpload() {
      this.loading = true;
      this.$store.dispatch('articles/uploadPurchaseStatistics', {
        file: this.file,
      }).then(() => {

        this.$toast( 'Purchase statistics added. Please wait for the background processes to complete');
        this.$store.dispatch('articles/fetchArticles');

        this.reset();
      });
    },

    reset() {
      this.loading = false;
      this.$store.dispatch('articles/closeStatisticsUploadModal');
    },
  }

}
</script>

