<script>
import {AgGridVue} from "ag-grid-vue";
import PriceDisplayCell from "../grid/PriceDisplayCell";
import ArticleTitleDisplayCell from "../grid/ArticleTitleDisplayCell";
import {mapState} from "vuex";

export default {
  name: "BenchmarksGrid",
  data() {
    return {
      gridApi: null,
      columnApi: null,
      rowBuffer: null,
      rowModelType: null,
      loading: true,
      cacheBlockSize: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      debounceLength: null,
      maxBlocksInCache: null,
      datasource: null,
      defaultColDef: {
        floatingFilter: true,
      },
      columnDefs: this.determineColumns()
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AgGridVue, PriceDisplayCell, ArticleTitleDisplayCell
  },
  created() {
    this.rowBuffer = 0;
    this.rowModelType = 'infinite';
    this.cacheBlockSize = 45;
    this.cacheOverflowSize = 0;
    this.maxConcurrentDatasourceRequests = 2;
    this.infiniteInitialRowCount = 5000;
    this.maxBlocksInCache = 1;
    this.debounceLength = 600; //ms
  },
  computed: {
    ...mapState({
      supplierFilter: (state) => state.articles.filterSupplier,
      maxPricePosition: (state) => state.articles.filterSupplierMaxPosition,
      shouldRefresh: (state) => state.articles.shouldRefresh
    })
  },
  methods: {
    determineColumns() {

      if (this.getCurrentInstance() === 'beverages') {
        return [
          {
            field: "latina_article_id", headerName: "Art#", filter: true, minWidth: 120,
            cellStyle: {'text-align': 'left'},
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ]
            }
          },
          {
            field: "title",
            minWidth: 300,
            resizable: true,
            headerName: "Article Title",
            cellStyle: {'text-align': 'left'},
            width: 400,
            filter: true,
            cellRenderer: 'ArticleTitleDisplayCell',
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'empty',
                {
                  displayKey: 'contains',
                  displayName: 'Contains',
                  numberOfInputs: 1
                },
              ],
              suppressAndOrCondition: true,
            },
          },
          {
            field: "economical_stock", headerName: "Ec. Stock", minWidth: 100, cellRenderer: (params) => {

              if (params.value === undefined) {
                return '';
              }

              if (params.value === null) {
                return 'N/A';
              }

              return params.value;
            },
            cellStyle: {'text-align': 'right'},
            filter: 'agNumberColumnFilter',
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'equals',
                'greaterThan',
                'lessThan'
              ],
              suppressAndOrCondition: true
            },
          },
          {
            field: "is_gift_box", headerName: "GB", minWidth: 80, cellRenderer: (params) => {
              if (params.value === undefined) {
                return '';
              }
              return params.value === 0 ? 'NGB' : 'GB';
            },
            cellStyle: {'text-align': 'right'},
            filter: 'agTextColumnFilter',
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'empty',
                {
                  displayKey: 'equalsGb',
                  displayName: 'GB',
                  predicate: (_, cellValue) => cellValue === 'GB',
                  numberOfInputs: 0,
                },
                {
                  displayKey: 'equalsNgb',
                  displayName: 'NGB',
                  predicate: (_, cellValue) => cellValue === 'NGB',
                  numberOfInputs: 0,
                },
              ],
              suppressAndOrCondition: true,
            },
          },
          {
            field: "is_refillable", headerName: "NRF", minWidth: 80,
            filter: 'agTextColumnFilter',
            cellStyle: {'text-align': 'right'},
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'empty',
                {
                  displayKey: 'equalsRf',
                  displayName: 'Refillable',
                  predicate: (_, cellValue) => cellValue === 'RF',
                  numberOfInputs: 0,
                },
                {
                  displayKey: 'equalsNrf',
                  displayName: 'Non-Refillable',
                  predicate: (_, cellValue) => cellValue === 'NRF',
                  numberOfInputs: 0,
                },
              ],
              suppressAndOrCondition: true,
            },
          },
          {
            field: "content_count",
            headerName: "Btl/Case",
            minWidth: 110,
            filter: 'agNumberColumnFilter',
            filterParams: {
              buttons: [
                'apply',
                  'reset'
              ],
              filterOptions: [
                'equals',
                'greaterThan',
                'lessThan'
              ],
              suppressAndOrCondition: true
            }
          },
          {
            field: "inner_content_count",
            headerName: "Content",
            minWidth: 120,
            filter: 'agNumberColumnFilter',
            filterParams: {
              buttons: [
                'apply',
                  'reset'
              ],
              filterOptions: [
                'equals',
                'greaterThan',
                'lessThan'
              ],
              suppressAndOrCondition: true
            }
          },
          {
            field: "alcohol_percentage", headerName: "Alc. %", minWidth: 120,
            filter: 'agNumberColumnFilter',
            filterParams: {
              buttons: [
                'apply',
                  'reset'
              ],
              filterOptions: [
                'equals',
                'greaterThan',
                'lessThan'
              ],
              suppressAndOrCondition: true
            }
          },
          {
            field: "has_paper_trail", headerName: "Ppr Trl", minWidth: 120,
            filter: 'agTextColumnFilter',
            cellRenderer: (params) => {
              if (params.value === undefined) {
                return '';
              }
              return params.value === 0 ? 'No' : 'Yes';
            },
            filterParams: {
              buttons: [
                'apply',
                  'reset'
              ],
              filterOptions: [
                'empty',
                {
                  displayKey: 'equalsYes',
                  displayName: 'Yes',
                  predicate: (_, cellValue) => cellValue === '1',
                  numberOfInputs: 0,
                },
                {
                  displayKey: 'equalsNo',
                  displayName: 'No',
                  predicate: (_, cellValue) => cellValue === '0',
                  numberOfInputs: 0,
                },
              ],
              suppressAndOrCondition: true,
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #1",
            cellClass: 'align-middle',
            resizable: true,
            cellRenderer: "PriceDisplayCell",
            filter: 'agNumberColumnFilter',
            filterParams: {
              buttons: [
                'apply',
                  'reset'
              ],
              filterOptions: [
                'empty',
                {
                  displayKey: 'notBlanks',
                  displayName: 'Not Blank',
                  predicate: (_, cellValue) => cellValue !== null,
                  numberOfInputs: 0,
                },
                {
                  displayKey: 'blanks',
                  displayName: 'Blank',
                  predicate: (_, cellValue) => cellValue === null,
                  numberOfInputs: 0,
                },
              ],
              suppressAndOrCondition: true,
            },
            minWidth: 150,
            cellRendererParams: {
              index: 0
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #2",
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            resizable: true,
            cellRendererParams: {
              index: 1
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #3",
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            resizable: true,
            cellRendererParams: {
              index: 2
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #4",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 3
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #5",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 4
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #6",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 5
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #7",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 6
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #8",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 7
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #9",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 8
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #10",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 9
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #11",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 10
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #12",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 11
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #13",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 12
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #14",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 13
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #15",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 14
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #16",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 15
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #17",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 16
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #18",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 17
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #19",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 18
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #20",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 19
            }
          },
        ];
      } else {
        return [
          {
            field: "latina_article_id", headerName: "Art#", filter: true, minWidth: 120,
            cellStyle: {'text-align': 'left'}
          },
          {
            field: "title",
            minWidth: 300,
            resizable: true,
            headerName: "Article Title",
            cellStyle: {'text-align': 'left'},
            width: 400,
            filter: true,
            cellRenderer: 'ArticleTitleDisplayCell',
            filterParams: {
              filterOptions: [
                'empty',
                {
                  displayKey: 'contains',
                  displayName: 'Contains',
                  numberOfInputs: 1
                },
              ],
              suppressAndOrCondition: true,
            },
          },
          {
            field: "economical_stock", headerName: "Ec. Stock", minWidth: 100, cellRenderer: (params) => {

              if (params.value === undefined) {
                return '';
              }

              if (params.value === null) {
                return 'N/A';
              }

              return params.value;
            },
            cellStyle: {'text-align': 'right'},
            filter: 'agNumberColumnFilter',
            filterParams: {
              filterOptions: [
                'equals',
                'greaterThan',
                'lessThan'
              ],
              suppressAndOrCondition: true
            },
          },
          {
            field: "type",
            headerName: "Type",
            resizable: true,
            minWidth: 160,
            filter: true,
            cellStyle: {'text-align': 'left'},
            filterParams: {
              filterOptions: [
                'empty',
                {
                  displayKey: 'contains',
                  displayName: 'Contains',
                  numberOfInputs: 1
                },
              ],
              suppressAndOrCondition: true
            }
          },
          {
            field: "inner_content_count",
            headerName: "Content",
            minWidth: 120,
            cellStyle: {'text-align': 'left'},
            filter: 'agNumberColumnFilter',
            filterParams: {
              filterOptions: [
                'equals',
                'greaterThan',
                'lessThan'
              ],
              suppressAndOrCondition: true
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #1",
            cellClass: 'align-middle',
            resizable: true,
            cellRenderer: "PriceDisplayCell",
            filter: 'agNumberColumnFilter',
            filterParams: {
              filterOptions: [
                'empty',
                {
                  displayKey: 'notBlanks',
                  displayName: 'Not Blank',
                  predicate: (_, cellValue) => cellValue !== null,
                  numberOfInputs: 0,
                },
                {
                  displayKey: 'blanks',
                  displayName: 'Blank',
                  predicate: (_, cellValue) => cellValue === null,
                  numberOfInputs: 0,
                },
              ],
              suppressAndOrCondition: true,
            },
            minWidth: 150,
            cellRendererParams: {
              index: 0
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #2",
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            resizable: true,
            cellRendererParams: {
              index: 1
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #3",
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            resizable: true,
            cellRendererParams: {
              index: 2
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #4",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 3
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #5",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 4
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #6",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 5
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #7",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 6
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #8",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 7
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #9",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 8
            }
          },
          {
            field: "ordered_prices",
            headerName: "Price #10",
            resizable: true,
            cellClass: 'align-middle',
            cellRenderer: "PriceDisplayCell",
            minWidth: 150,
            cellRendererParams: {
              index: 9
            }
          }
        ];
      }
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    async fetchArticles(params) {
      this.loading = true;
      if (this.supplierFilter) {
        params.supplier = this.supplierFilter;
        params.maxPricePosition = this.maxPricePosition;
      } else {
        params.supplier = null;
        params.maxPricePosition = null;
      }

      return await this.$store.dispatch('articles/fetchArticles', params);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.sizeToFit();

      const dataSource = {
        rowCount: undefined,
        getRows: (params) => {
          this.fetchArticles(params).then((response) => {
            params.successCallback(response.data, response.lastRow);
            this.loading = false;
          }, () => {
          });
        }
      }

      this.gridApi.setDatasource(dataSource);
    }
  },
  watch: {
    supplierFilter: function () {
      this.gridApi.refreshInfiniteCache();
    },
    shouldRefresh: function () {
      this.gridApi.refreshInfiniteCache();
    },
  }
};
</script>

<template>
  <b-overlay :show="loading" rounded="sm">
    <ag-grid-vue style=" width: 100%; min-height: 730px; height: 77vh"
                 class="ag-theme-alpine"
                 @grid-ready="onGridReady"
                 :columnDefs="columnDefs"
                 :rowBuffer="rowBuffer"
                 :rowModelType="rowModelType"
                 :defaultColDef="defaultColDef"
                 :blockLoadDebounceMillis="debounceLength"
                 :cacheBlockSize="cacheBlockSize"
                 :cacheOverflowSize="cacheOverflowSize"
                 :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
                 :infiniteInitialRowCount="infiniteInitialRowCount"
                 :maxBlocksInCache="maxBlocksInCache">
    </ag-grid-vue>
  </b-overlay>
</template>


<style lang="scss">

.align-middle {
  display: flex;
  align-items: center;
}
</style>